html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;  
}

body {
  display: flex;  
  justify-content: center;
  align-items: center; 
}

.loader {
  color: #fff;
  text-align: center;
  font-family: Consolas, Menlo, Monaco, monospace;
  font-weight: bold;
  font-size: '10vh';
  
  opacity: 0.8;

  
}

.loader span {
    display: inline-block;
    animation: pulse 0.4s alternate infinite ease-in-out;

    &:nth-child(odd) {
      animation-delay: 0.4s;
    }
  }

@keyframes pulse {
  to {
    transform: scale(0.8);
    opacity: 0.5;
  }
}