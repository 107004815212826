body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background: linear-gradient(89.7deg,
      rgb(0, 0, 0) -10.7%,
      rgb(53, 92, 125) 88.8%);
  color: white;
}